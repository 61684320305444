import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Input from "./form/Input";

const Login = (props) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  // we use the outlet's context to handle lifting state
  const { setJwtToken, setAlertClassName, setAlertMessage, toggleRefresh } = useOutletContext();

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    // build the request payload
    let payload = {
      email: email,
      password: password,
    }

    const requestOptions = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    }

    fetch(`${process.env.REACT_APP_BACKEND}/authenticate`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.error) {
          setAlertClassName("alert-danger alert-dismissible ");
          setAlertMessage(data.message);
        } else {
          setJwtToken(data.access_token);
          setAlertClassName("d-none");
          setAlertMessage("");
          navigate("/");
          toggleRefresh(true);
        }
      })
      .catch(error => {
        setAlertClassName("alert-danger alert-dismissible ");
        setAlertMessage(error);
      })

    // want to populate jwt and lift state

  };

  return (
    <>
      <div className="col-md-6 offset-md-3">
        <h2>Login</h2>
        <hr />
        <form onSubmit={handleSubmit}>
          <Input
            title="Email Address"
            type="email"
            className="form-control"
            name="email"
            autoComplete="enail-new"
            onChange={(event) => setEmail(event.target.value)}
          ></Input>

          <Input
            title="Password"
            type="password"
            className="form-control"
            name="password"
            autoComplete="first-name-new"
            onChange={(event) => setPassword(event.target.value)}
          ></Input>
          <hr />
          <input
            type="submit"
            className="btn btn-primary"
            value="Login"
          ></input>
        </form>
      </div>
    </>
  );
};

export default Login;
