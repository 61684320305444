import { useState, useEffect } from "react";
import { Link } from "react-router-dom";


const Movies = () => {
    // We get our movies value by using the location hook, and reading
    // passed state.
    const [movies, setMovies] = useState([]);

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");

            const requestOptions = {
                method: "GET",
                headers: headers,
            }

            fetch(`${process.env.REACT_APP_BACKEND}/movies`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    setMovies(data);
                })
                .catch(err => {
                    console.log(err);
                })
        }

        return () => { ignore = true; };
    }, [])

    return (
        <>
            <h2>Movies</h2>
            <hr />
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Movie</th>
                        <th>Release Date</th>
                        <th>Rating</th>
                    </tr>
                </thead>
                <tbody>
                    {movies.map((m) => (
                        <tr key={m.id}>
                            <td>
                                <Link to={`/movies/${m.id}`}>
                                    {m.title}
                                </Link>
                            </td>
                            <td>{new Date(m.release_date).toLocaleDateString()}</td>
                            <td>{m.mpaa_rating}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default Movies;