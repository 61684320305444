import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import Input from './form/Input';


const GraphQL = () => {

    const [movies, setMovies] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [fullList, setFullList] = useState();

    const performSearch = () => {
        // we specify what we want from graphql in the payload
        const payload = `
        {
            search(titleContains: "${searchTerm}") {
                id
                title
                runtime
                release_date
                mpaa_rating
            }
        }`;

        const headers = new Headers();
        headers.append("Content-Type", "application/graphql");

        const requestOptions = {
            method: "POST",
            body: payload,
            headers: headers,
        };

        fetch(`${process.env.REACT_APP_BACKEND}/graph`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                // here we use response.data.search, since we specified the action "search" in the payload
                let theList = Object.values(response.data.search);
                setMovies(theList);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleChange = (event) => {
        event.preventDefault();

        let value = event.target.value;
        setSearchTerm(value);

        if (value.length > 2) {
            performSearch();
        } else {
            setMovies(fullList);
        }
    }

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            // here we just specify what we want from graphql
            const payload = `
            {
                list {
                    id
                    title
                    runtime
                    release_date
                    mpaa_rating
                }
            }`;

            const headers = new Headers();
            headers.append("Content-Type", "application/graphql");

            const requestOptions = {
                method: "POST",
                headers: headers,
                body: payload,
            }

            fetch(`${process.env.REACT_APP_BACKEND}/graph`, requestOptions)
                .then((response) => response.json())
                .then((response) => {
                    // here we use response.data.list, since we specified the action "list" in the payload
                    let theList = Object.values(response.data.list);
                    setMovies(theList);
                    // we save the full list of movies so we can restore it when clearing the search form
                    setFullList(theList);
                })
                .catch(err => {
                    console.log(err);
                })
        }

        return () => { ignore = true; };
    }, [])

    return (
        <>
            <h2>Movies via GraphQL</h2>
            <hr />
            <form onSubmit={handleChange}>
                <Input
                    title={"Search"}
                    type={"search"}
                    name={"search"}
                    className={"form-control"}
                    value={searchTerm}
                    onChange={handleChange}
                />
            </form>

            {movies ? (

                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Movie</th>
                            <th>Release Date</th>
                            <th>Rating</th>
                        </tr>
                    </thead>
                    <tbody>
                        {movies.map((m) => (
                            <tr key={m.id}>
                                <td>
                                    <Link to={`/movies/${m.id}`}>
                                        {m.title}
                                    </Link>
                                </td>
                                <td>{new Date(m.release_date).toLocaleDateString()}</td>
                                <td>{m.mpaa_rating}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            ) : (
                <p>No movies (yet)!</p>
            )}
        </>
    )
}

export default GraphQL;