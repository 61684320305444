import { forwardRef } from "react";

const CheckBox = forwardRef((props, ref) => {
    return (
        <div className="form-check">
            <input
                id={props.id}
                className="form-check-input"
                type="checkbox"
                value={props.value}
                name={props.name}
                onChange={props.onChange}
                ref={ref}
                checked={props.checked}
            />
            <label className="form-check-label" htmlFor={props.id}>
                {props.title}
            </label>
        </div>
    );
});

export default CheckBox;