import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Genres = () => {

    const [genres, setGenres] = useState([]);
    const [error, setError] = useState(null); // network error

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");

            const requestOptions = {
                method: "GET",
                headers: headers,
            }

            fetch(`${process.env.REACT_APP_BACKEND}/genres`, requestOptions)
                .then((response) => {
                    if (response.status !== 200) {
                        setError("Invalid response code: " + response.status)
                    }
                    return response.json()
                })
                .then((data) => {
                    setGenres(data);
                })
                .catch(err => {
                    console.log(err);
                })
        }

        return () => { ignore = true; };
    }, []);

    if (error !== null) {
        return <div>Error: {error.message}</div>;
    } else {
        return (
            <>
                <h2>Genres</h2>
                <hr />
                <div className="list-group">
                    {genres.map((g) => (
                        <Link
                            key={g.id}
                            className="list-group-item list-group-item-action"
                            to={`/genres/${g.id}`}
                            state={
                                {
                                    genreName: g.genre,
                                }
                            }
                        >
                            {g.genre}
                        </Link>
                    ))}
                </div>
            </>
        )
    }
}

export default Genres;