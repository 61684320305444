import { useState, useEffect } from "react";
import { useNavigate, useOutletContext, Link } from "react-router-dom";

const ManageCatalogue = () => {
    const [movies, setMovies] = useState([]);
    const { jwtToken } = useOutletContext()
    const navigate = useNavigate();

    useEffect(() => {
        let ignore = false;

        if (jwtToken === "") {
            navigate("/login");
            return
        }

        if (!ignore) {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", "Bearer " + jwtToken);

            let requestOptions = {
                method: "GET",
                headers: headers,
                credentials: "include",
            }

            fetch(`${process.env.REACT_APP_BACKEND}/admin/movies`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    setMovies(data);
                    console.log("Data:", data)
                })
                .catch(err => {
                    console.log(err);
                })
        }

        return () => { ignore = true; };
    }, [jwtToken, navigate])

    return (
        <>
            <h2>Manage Catalogue</h2>
            <hr />
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Movie</th>
                        <th>Release Date</th>
                        <th>Rating</th>
                    </tr>
                </thead>
                <tbody>
                    {movies.map((m) => (
                        <tr key={m.id}>
                            <td>
                                <Link to={`/admin/movies/${m.id}`}>
                                    {m.title}
                                </Link>
                            </td>
                            <td>{m.release_date}</td>
                            <td>{m.mpaa_rating}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default ManageCatalogue;