import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom'

const OneGenre = () => {
    // we need to use location to get the "prop" passed from parent link
    const location = useLocation();
    const { genreName } = location.state;

    // set a stateful variable to hold our movies (if any)
    const [movies, setMovies] = useState([]);

    // get the id from the url
    let { id } = useParams();

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");

            const requestOptions = {
                method: "GET",
                headers: headers,
            }

            fetch(`${process.env.REACT_APP_BACKEND}/movies/genres/${id}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    setMovies(data);
                })
                .catch(err => {
                    console.log(err);
                })
        }

        // cleanup - set ignore to true; this way, the fetch is not executed twice!
        return () => { ignore = true;  };
    }, [id])

    return (
        <>
            <h2>Genre: {genreName}</h2>

            <hr />

            {movies ? (

                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Movie</th>
                            <th>Release Date</th>
                            <th>Rating</th>
                        </tr>
                    </thead>
                    <tbody>
                        {movies.map((m) => (
                            <tr key={m.id}>
                                <td>
                                    <Link to={`/movies/${m.id}`}>
                                        {m.title}
                                    </Link>
                                </td>
                                <td>{m.release_date}</td>
                                <td>{m.mpaa_rating}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            ) : (
                <p>No movies in this genre (yet)!</p>
            )}

        </>
    )
}

export default OneGenre;