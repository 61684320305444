import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "./form/Input";
import Select from "./form/Select";
import TextArea from "./form/TextArea";
import CheckBox from "./form/Checkbox";
import Swal from 'sweetalert2';


const EditMovie = () => {
    // declare a navigate variable to redirect as necessary
    const navigate = useNavigate();

    // we are using controlled form, so we have to 
    // specify default values for the movie
    const [movie, setMovie] = useState({
        id: 0,
        title: "",
        release_date: "",
        runtime: "",
        mpaa_rating: "",
        description: "",
        genres: [],
        genres_array: [Array(13).fill(false)],
    });

    // these are the options for our dropdown
    const mpaaOptions = [
        { id: "G", value: "G" },
        { id: "PG", value: "PG" },
        { id: "PG13", value: "PG13" },
        { id: "R", value: "R" },
        { id: "NC17", value: "NC17" },
        { id: "18A", value: "18A" },
    ];

    // our jwt token, from context
    const { jwtToken } = useOutletContext();

    // errors
    const [error, setError] = useState(null); // network error
    const [errors, setErrors] = useState([]); // validation errors

    // get the id from the URL
    let { id } = useParams();
    if (id === undefined) {
        id = 0;
    }

    useEffect(() => {
        if (jwtToken === "") {
            navigate("/login");
            return
        }

        // we define a variable ignore, and set it to false
        let ignore = false;

        // only execute fetching logic if ignore is false
        if (!ignore) {
            if (id === 0) {
                // we need to ensure that movie is set to empty, in case the "add movie" link
                // is chosen while someone is editing an existing movie
                setMovie({
                    id: 0,
                    title: "",
                    release_date: "",
                    runtime: "",
                    mpaa_rating: "",
                    description: "",
                    genres: [],
                    genres_array: [Array(13).fill(false)],
                });

                // adding a movie
                const headers = new Headers();
                headers.append("Content-Type", "application/json");
                headers.append("Authorization", "Bearer " + jwtToken);

                const requestOptions = {
                    method: "GET",
                    headers: headers,
                }

                // from the back end, we'll get a list of genres sorted by name
                fetch(`${process.env.REACT_APP_BACKEND}/genres`, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        const checks = [];

                        data.forEach(g => {
                            checks.push({ id: g.id, checked: false, genre: g.genre });
                        })

                        // because we are setting state on movie, we'll get a warning if we
                        // don't include movie in the dependency array. If we include it,
                        // we'll get an infinite loop where useEffect is called repeatedly,
                        // forever. Setting movie this way solves that problem (functional update).
                        setMovie(m => ({
                            ...m,
                            genres: checks,
                            genres_array: [],
                        }))
                    })
                    .catch(error => {
                        console.log(error);
                    })
            } else {
                // editing a movie
                const headers = new Headers();
                headers.append("Content-Type", "application/json");
                headers.append("Authorization", "Bearer " + jwtToken);

                const requestOptions = {
                    method: "GET",
                    headers: headers,
                }

                // from the back end, we'll get data.movie and data.genres

                fetch(`${process.env.REACT_APP_BACKEND}/admin/movies/${id}`, requestOptions)
                    .then((response) => {
                        if (response.status !== 200) {
                            setError("Invalid response code: " + response.status)
                        }
                        return response.json()
                    })
                    .then((data) => {
                        // fix release date format
                        data.movie.release_date = new Date(data.movie.release_date).toISOString().split('T')[0];

                        // assume we are getting an array like this from the back end for genres
                        // [
                        //     {id: 5, genre: "Action"},
                        //     {id: 11, genre: "Adventure"},
                        // ]
                        // also assume that the ids are sorted by name

                        // checks will be an array of the same length as genres, with each entry
                        // consisting of a object like this {id: x, checked: bool, genre: genre name}
                        const checks = [];

                        data.genres.forEach(g => {
                            if (data.movie.genres_array.indexOf(g.id) !== -1) {
                                checks.push({ id: g.id, checked: true, genre: g.genre });
                            } else {
                                checks.push({ id: g.id, checked: false, genre: g.genre });
                            }
                        })

                        // set state
                        setMovie({
                            ...data.movie,
                            genres: checks
                        })
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        }

        // cleanup - set ignore to true; this way, the fetch is not executed twice!
        return () => {
            ignore = true;
        };
    }, [id, jwtToken, navigate])

    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // validation
        let errors = [];
        let required = [
            { field: movie.title, name: "title" },
            { field: movie.release_date, name: "release_date" },
            { field: movie.runtime, name: "runtime" },
            { field: movie.description, name: "description" },
            { field: movie.mpaa_rating, name: "mpaa_rating" },
        ]

        required.forEach(function (obj) {
            if (obj.field === "") {
                errors.push(obj.name);
            }
        })

        if (movie.genres_array.length === 0) {
            // alert("You must choose at least one genre!");
            Swal.fire({
                title: 'Error!',
                text: 'You must choose at least one genre!',
                icon: 'error',
                confirmButtonText: 'OK'
            })
            errors.push("genres");
        }

        setErrors(errors)

        if (errors.length > 0) {
            return false;
        }

        // passed validation, so save changes
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + jwtToken);

        // assume we are adding a movie
        let method = "PUT";

        // change method to patch if we are updating a movie
        if (movie.id > 0) {
            method = "PATCH";
        }

        const requestBody = movie;
        // we need to convert the values in JSON for release date (to date)
        // and runtime (to int)
        requestBody.release_date = new Date(movie.release_date);
        requestBody.runtime = parseInt(movie.runtime);

        let requestOptions = {
            body: JSON.stringify(requestBody),
            method: method,
            headers: headers,
            credentials: "include",
        }

        fetch(`${process.env.REACT_APP_BACKEND}/admin/movies/${movie.id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {

                } else {
                    navigate("/manage-catalogue")
                }
            })
            .catch(err => {
                console.log(err);
            })

    }

    const handleChange = () => (event) => {
        let value = event.target.value;
        let name = event.target.name;
        setMovie({
            ...movie,
            [name]: value,
        });
    }

    const handleCheck = (event, position) => {
        // console.log("checked called")
        // console.log("value in handlecheck:", event.target.value)
        // console.log("check is", event.target.checked);
        // console.log("position is", position);

        let tmpArr = movie.genres;
        tmpArr[position].checked = !tmpArr[position].checked;

        let tmpIDs = movie.genres_array;
        if (!event.target.checked) {
            // checked - remove from array of ids
            tmpIDs.splice(tmpIDs.indexOf(event.target.value));
        } else {
            // unchecked - add to array of ids
            tmpIDs.push(parseInt(event.target.value, 10));
        }

        // genres_array will be pushed to the back end on save;
        // we'll delete all existing ids from movies_genres,
        // and then add the new ones.
        setMovie({
            ...movie,
            genres_array: tmpIDs,
        })
    }

    if (error !== null) {
        return <div>Error: {error.message}</div>;
    } else {

        return (
            <>
                <h2>Add/Edit Movie</h2>
                <hr />
                {/* <pre>{JSON.stringify(movie, null, 3)}</pre> */}

                <form onSubmit={handleSubmit}>

                    <input
                        type="hidden"
                        name="id"
                        id="id"
                        value={movie.id}
                    />

                    <Input
                        title={"Title"}
                        className={"form-control"}
                        type={"text"}
                        name={"title"}
                        value={movie.title}
                        onChange={handleChange("title")}
                        errorDiv={hasError("title") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a title"}
                    />

                    <Input
                        title={"Release Date"}
                        className={"form-control"}
                        type={"date"}
                        name="release_date"
                        value={movie.release_date}
                        onChange={handleChange("release_date")}
                        errorDiv={hasError("release_date") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a release date"}
                    />

                    <Input
                        title={"Runtime"}
                        className={"form-control"}
                        type={"text"}
                        name="runtime"
                        value={movie.runtime}
                        onChange={handleChange("runtime")}
                        errorDiv={hasError("runtime") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a runtime"}
                    />

                    <Select
                        title={"MPAA Rating"}
                        name={"mpaa_rating"}
                        options={mpaaOptions}
                        value={movie.mpaa_rating}
                        onChange={handleChange("mpaa_rating")}
                        placeholder="Choose..."
                        errorMsg={"Please choose"}
                        errorDiv={hasError("mpaa_rating") ? "text-danger" : "d-none"}
                    />

                    <TextArea
                        title={"Description"}
                        name={"description"}
                        value={movie.description}
                        rows={"3"}
                        onChange={handleChange("description")}
                        errorDiv={hasError("description") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a description"}
                    />

                    <hr />

                    <h3>Genres</h3>

                    {movie.genres && movie.genres.length > 1 &&
                        <>
                            {Array.from(movie.genres).map((g, index) => (
                                <CheckBox
                                    title={g.genre}
                                    name={"genre"}
                                    key={index}
                                    id={"genre-" + index}
                                    onChange={(event) => handleCheck(event, index)}
                                    value={g.id}
                                    checked={movie.genres[index].checked}
                                />
                            ))}
                        </>
                    }

                    <hr />
                    <button className="btn btn-primary">Save</button>

                </form>

            </>
        )
    }
}

export default EditMovie;