import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const Movie = () => {
    // declare state variables
    const [movie, setMovie] = useState({})
    const [rd, setRd] = useState(new Date());

    // get the id from the url
    let { id } = useParams();

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");

            const requestOptions = {
                method: "GET",
                headers: headers,
            }

            fetch(`${process.env.REACT_APP_BACKEND}/movies/${id}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    setMovie(data);
                    setRd(new Date(data.release_date));
                })
                .catch(err => {
                    console.log(err);
                })
        }

        return () => { ignore = true; };
    }, [id])

    if (movie.genres) {
        movie.genres = Object.values(movie.genres);
    } else {
        movie.genres = [];
    }

    return (
        <>
            <h2>Movie: {movie.title}</h2>
            <small><em>{rd.toLocaleDateString()}, {movie.runtime} minutes, Rated {movie.mpaa_rating}</em></small><br />
            {movie.genres.map((g) => (
                <Link
                    to={`/genres/${g.id}`}
                    state={
                        {
                            genreName: g.genre,
                        }
                    }>
                    <span key={g.genre} className="badge bg-secondary me-2">{g.genre}</span>
                </Link>
            ))}
            <hr />
            {movie.Image !== "" &&
                <div className="mb-3">
                    <img src={`https://image.tmdb.org/t/p/w200/${movie.image}`} alt="poster" width="200" height="300" />
                </div>
            }

            {movie.description}
        </>
    )
}

export default Movie;