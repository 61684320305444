import { useState, useEffect, useCallback } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import './App.css';
import Alert from './components/Alert';

const App = () => {
  // state
  const [jwtToken, setJwtToken] = useState("");
  const [user, setUser] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [alertClassName, setAlertClassName] = useState("alert d-none");


  const [ticking, setTicking] = useState(false);
  const [tickInterval, setTickInterval] = useState();

  // navigation
  const navigate = useNavigate();

  // logout sets our saved token to empty, and then calls the api
  // to delete the http only cookie with the refresh token.
  const logOut = () => {
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    }
    fetch(`${process.env.REACT_APP_BACKEND}/logout`, requestOptions)
      .catch(error => {
        console.log("Error calling /api/logout:", error)
      })
      .finally(() => {
        setJwtToken("");
        toggleRefresh(false);
      })
    navigate("/");
  }

  let listClasses = `list-group-item list-group-item-action`

  const toggleRefresh = useCallback((status) => {
    if (status) {
      let i = setInterval(() => {
        const requestOptions = {
          method: 'GET',
          credentials: 'include',
        }

        fetch(`${process.env.REACT_APP_BACKEND}/refresh`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.access_token) {
              // update access_token variable
              setJwtToken(data.access_token);
            }
          })
          .catch(error => {
            console.log("user is not logged in")
          })
      }, 600000);
      setTickInterval(i);
      setTicking(true);
    } else {
      setTickInterval(null);
      setTicking(false);
      clearInterval(tickInterval);
    }
  }, [tickInterval])

  // when this app loads, we call the back end to see if we have a valid,
  // non-expired jwt refresh token. If we do, we log the user in automatically.
  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      if (jwtToken === "") {
        // try to refresh
        const requestOptions = {
          method: 'GET',
          credentials: 'include',
        }

        fetch(`${process.env.REACT_APP_BACKEND}/refresh`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.access_token) {
              // update access_token variable
              setJwtToken(data.access_token);
              toggleRefresh(true);
            }
          })
          .catch(error => {
            console.log("user is not logged in")
          })
      }

      return () => { ignore = true; };
    }
  }, [jwtToken, setTicking, ticking, toggleRefresh])

  return (
    <div className="container">

      <div className="row">
        <div className="col">
          <h1 className="mt-3">Go Watch a Movie!</h1>
        </div>
        <div className="col text-end">
          {jwtToken === ""
            ? <Link to="/login"><span className="badge bg-success">Login</span></Link>
            : <a href="#!" onClick={logOut}><span className="badge bg-danger">Logout</span></a>}
        </div>
        <hr className="mb-3"></hr>
      </div>

      <div className="row">
        <div className="col-md-2">
          <nav>
            <div className="list-group">
              <Link to="/" className={listClasses} >Home</Link>
              <Link to="/movies" className={listClasses} >Movies</Link>
              <Link to="/genres" className={listClasses}>Genres</Link>
              {jwtToken !== "" &&
                <>
                  <Link to="/admin/movies/0" className={listClasses}>Add movie</Link>
                  <Link to="/manage-catalogue" className={listClasses}>Manage Catalogue</Link>
                  <Link to="/graphql" className={listClasses}>GraphQL</Link>
                </>
              }
            </div>
          </nav>
        </div>

        <div className="col-md-10">

          {/* <a className="btn btn-outline-secondary" href="#!" onClick={toggleRefresh}>Toggle Interval</a> */}


          <Alert
            message={alertMessage}
            className={alertClassName}
          ></Alert>

          <Outlet context={{
            jwtToken, setJwtToken,
            user, setUser,
            alertMessage, setAlertMessage,
            alertClassName, setAlertClassName,
            ticking, setTicking,
            tickInterval, setTickInterval,
            toggleRefresh,
          }} />
        </div>
      </div>

    </div>
  );
}

export default App;
